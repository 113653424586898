













































import { Component } from 'vue-property-decorator'

import { AbstractModal } from '../../../shared/organisms/AbstractModal'
import { ButtonLinkProps } from '../../../../dsl/atoms/ButtonLink'
import { TabOption } from '../../../../dsl/abstract/components/Link/Link.contracts'
import { AddedToFavouriteModalPayload } from './AddedToFavouriteModal.contracts'
import { ImageProps } from '../../../../dsl/atoms/Image'
import { toImageProps } from '../../../shared/support'
import { AllowedImageRatio } from '../../../../contexts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'AddedToFavouriteModal' })
export class AddedToFavouriteModal extends AbstractModal<AddedToFavouriteModalPayload> {
  /**
   * Determines link for `favourites` page.
   */
  public get favouritePageLink (): Omit<ButtonLinkProps, 'onClick' | 'theme'> {
    return {
      label: String(this.$t('front.products.organisms.addedToFavouriteModal.link.label')),
      isDisabled: false,
      target: {
        name: 'products.me.favourites'
      },
      tabTarget: TabOption.Self
    }
  }

  public get image (): ImageProps {
    return toImageProps(this.payload.product.image, AllowedImageRatio.Square)
  }
}

export default AddedToFavouriteModal
